
import page from '@/mixins/page';

export default {
  name: 'PageSearchHero',
  mixins: [page],
  computed: {
    css() {
      const image = !!this.component?.background_image
        ? `url(${this.$img(
            this.component?.background_image,
            { quality: 60 },
            { format: 'webp' }
          )})`
        : 'var(--primary)'

        const mobileImage = !!this.component?.background_image
          ? `url("${this.$img(
              this.component?.background_image,
              { quality: 50, width: '992px' },
              { format: 'webp' }
            )}")`
          : 'var(--primary)';

      return {
        '--url': image,
        '--mobile-url': mobileImage,
      };
    },
  },
};
